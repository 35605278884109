import React from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { PageLayout } from 'components/PageLayout';
import { CookiesPolicy } from 'components/CookiesPolicy';
import { DemandManagement } from 'components/DemandManagement';
import { UserManagement } from 'components/UserManagement';
import { OtherSettings } from 'components/OtherSettings';
import { PracticeOnlyRoutes } from 'components/Routes';

const Routes = () => {
  const routes = useRoutes([
    {
      element: <PageLayout />,
      children: [
        {
          path: '/',
          element: <UserManagement />,
        },
        {
          path: '/user-management',
          element: <UserManagement />,
        },
        {
          element: <PracticeOnlyRoutes redirectPath="/user-management" />,
          children: [
            {
              path: '/demand-management',
              element: <DemandManagement />,
            },
            {
              path: '/other-settings',
              element: <OtherSettings />,
            },
          ],
        },
      ],
    },
    {
      path: '/cookiepolicy',
      element: <CookiesPolicy />,
    },
  ]);

  return routes;
};

export const Router = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);
