import styled, { css } from 'styled-components';
import { ParagraphXSmallRegular } from 'components/Typography';
import { InputLabel, InputWrapper, InputElement } from '@econsult/econsult-ui/dist/components/Input/InputStyles';
import { Notification as SBNotification } from '@econsult/econsult-ui/dist/components/Notification';
import { formInputLabelStyles, formInputElementStyles } from 'components/FormInput/style';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const Legend = styled(ParagraphXSmallRegular).attrs({ as: 'legend' })`
  padding: 0;
`;

const stylesToFixFieldsetGapLayoutBug = css`
  && > * {
    margin: 0;
  }

  && > * + * {
    margin-top: 0.8rem;
  }
`;

export const FieldSet = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
  ${stylesToFixFieldsetGapLayoutBug};
`;

export const Validation = styled(SBNotification)`
  flex: 0;
`;

export const FlexInputLabel = styled(InputWrapper)`
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
`;

export const FlexInput = styled(InputElement)`
  ${formInputElementStyles};
  place-items: flex-start;
  resize: none;
  line-height: 1;
`;

export const FlexInputSpan = styled(InputLabel)`
  ${formInputLabelStyles};
`;
