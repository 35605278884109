import _sortedIndexBy from 'lodash/sortedIndexBy';
import _uniq from 'lodash/uniq';

/* inserts item in place in a sorted list */
export const insertSorted = (array, item, sortCompareFunction) => {
  const arrayCopy = [...array];
  arrayCopy.splice(_sortedIndexBy(arrayCopy, item, sortCompareFunction), 0, item);
  return arrayCopy;
};

export const hasDuplicates = list => list.length !== _uniq(list).length;
