import React from 'react';
import { ReactComponent as UserActionDeniedSvg } from 'assets/svg/userActionDenied.svg';
import { ReactComponent as EditUserIconSvg } from 'assets/svg/editUser.svg';
import { ReactComponent as DoorOpenIconSvg } from 'assets/svg/doorOpen.svg';
import { ReactComponent as DiscoverTuneIconSvg } from 'assets/svg/discoverTune.svg';

export const UnableToManageStateSvg = ({ className, alt, dataAutomation }) => <UserActionDeniedSvg data-automation={dataAutomation} className={className} alt={alt} />;
export const EditUserSvg = ({ className, alt, dataAutomation }) => <EditUserIconSvg data-automation={dataAutomation} className={className} alt={alt} />;
export const DoorOpenSvg = ({ className, alt, dataAutomation }) => <DoorOpenIconSvg data-automation={dataAutomation} className={className} alt={alt} />;
export const DiscoverTuneSvg = ({ className, alt, dataAutomation }) => <DiscoverTuneIconSvg data-automation={dataAutomation} className={className} alt={alt} />;
