import { useCallback, useEffect } from 'react';
import _delay from 'lodash/delay';

function updateHeight() {
  this.style.height = 'auto';
  this.style.height = `${this.scrollHeight}px`;
}

export const useAutoResizeTextarea = textareaRef => {
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${textarea.scrollHeight}px`;
      textarea.style.overflowY = 'hidden';
      textarea.addEventListener('input', updateHeight);
    }
    return () => {
      if (textarea) {
        textarea.removeEventListener('input', updateHeight);
      }
    };
  }, [textareaRef]);
  const triggerResize = useCallback(() => _delay(() => (
    textareaRef.current?.dispatchEvent(
      new Event('input', { bubbles: true, cancelable: true }),
    )
  ), 0), [textareaRef]);
  return { triggerResize };
};
