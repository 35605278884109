import { Modal as MuiModal } from '@econsult/econsult-ui/dist/components/Modal';
import { Div, ColumnDiv } from 'components/Div';
import { Paragraph } from '@econsult/econsult-ui/dist/components/shared/typography';
import styled from 'styled-components';
import { Button } from '@econsult/econsult-ui/dist/components/Button';
import { CloseIcon } from '@econsult/econsult-ui/dist/assets';

export const InvisibleButton = styled.button`
  padding: 5px;
  border-radius: 4px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const ModalContainer = styled(ColumnDiv)`
  background: ${({ theme }) => theme.colorNeutralBrightWhite};
  border-radius: 6px;
  width: 512px;
  height: auto;
  overflow-y: auto;
`;

export const ModalTitleAndCloseContainer = styled(Div)`
  background-color: ${({ theme }) => theme.colorNeutralBrightWhite};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 25px 40px 20px 40px;
  border-bottom: ${({ theme }) => theme.dividerMatchingRight};
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled(Paragraph).attrs({ typography: 'ParagraphBaseHeavy' })`
  ${({ theme }) => theme.fontStyles};
  color: ${({ theme }) => theme.brandingPrimaryShadeFour};
  font-weight: ${({ theme }) => theme.fontWeight600};
  margin: 0;
`;

export const ModalContentsOuter = styled(ColumnDiv)``;

export const ModalContentsInner = styled(ColumnDiv)`
  flex: 1 1 auto;
  margin: 0 40px 20px 40px;
  border-radius: 6px;
  font-weight: ${({ theme }) => theme.fontWeight600};
  font-size: ${({ theme }) => theme.fontSizeMedium};
  color: ${({ theme }) => theme.dropdownPrimary};
  padding-top: 16px;
  gap: 16px;
`;

export const ModalCloseButton = styled(InvisibleButton)`
  color: ${({ theme }) => theme.wrapperModalCloseButton};
  border: 2px solid transparent;
  outline: none;
  padding: 0;
  &:focus {
    border: 2px solid ${({ theme }) => theme.wrapperModalCloseButton};
  }
  &:active {
    outline: none;
  }
`;

export const ModalCloseIcon = styled(CloseIcon)`
  margin: 0;
  padding: 0;
`;

export const ModalConfirmButton = styled(Button).attrs({ state: 'secondary', size: 'small' })``;

export const ModalCancelButton = styled(Button).attrs({ size: 'small', fillStyle: 'borderless' })``;

export const ModalActionsContainer = styled(Div)`
  justify-content: ${({ centerActions }) => (centerActions ? 'center' : 'flex-end')};
  gap: 8px;
  margin-top: 16px;
  align-items: center;
`;

export const ModalTextSmall = styled(Paragraph).attrs({ typography: 'ParagraphXsmallRegular' })`
  color: ${({ theme }) => theme.colorPrimaryPrimary80};
  padding: 0;
  margin: 0;
  font-weight: 400;
  white-space: pre-wrap;
`;

export { MuiModal };
