import React from 'react';
import styled from 'styled-components';
import { AnchoredTooltip } from '@econsult/econsult-ui/dist/components/AnchoredTooltip';

const Tooltip = styled(AnchoredTooltip)`
  z-index: 33;
  *:focus > && {
    opacity: 1;
    transform: ${({ align }) => {
    switch (align) {
      case 'top':
        return 'translateX(-50%) translateY(-0.125rem);';
      case 'bottom':
        return 'translateX(-50%) translateY(0.125rem);';
      case 'left':
        return 'translateX(calc(-100% - 0.625rem)) translateY(-50%);';
      case 'right':
        return 'translateX(0.625rem) translateY(-50%);';
      default:
        return 'translateX(-50%) translateY(-0.125rem);';
    }
  }};
  }
`;

export const ContactAdminTooltip = ({ show, automationLabel }) => show && (
  <Tooltip
    automationLabel={automationLabel}
    label="Contact your practice admin"
    align="top"
  />
);
