import { ErrorToast, SuccessToast } from 'components/Toast';
import { STRINGS } from 'config';
import request from 'superagent';
import { toast } from '@econsult/econsult-ui/dist/components/Toast';
import { reportError } from 'utils/datadog';
import React from 'react';

const {
  API: { PRACTICE_URL },
  REDUCERS: {
    EMIS: {
      API_GET_EMIS_CREDENTIALS_REQUEST,
      API_GET_EMIS_CREDENTIALS_SUCCESS,
      API_GET_EMIS_CREDENTIALS_ERROR,
      API_POST_EMIS_CREDENTIALS_REQUEST,
      API_POST_EMIS_CREDENTIALS_SUCCESS,
      API_POST_EMIS_CREDENTIALS_ERROR,
      API_DELETE_EMIS_CREDENTIALS_REQUEST,
      API_DELETE_EMIS_CREDENTIALS_SUCCESS,
      API_DELETE_EMIS_CREDENTIALS_ERROR,
      CLEAR_EMIS_CREDENTIALS,
    },
  },
} = STRINGS;

export const apiGetEmisCredentials = practiceId => dispatch => {
  dispatch({ type: API_GET_EMIS_CREDENTIALS_REQUEST });

  return request.get(`${PRACTICE_URL}/${practiceId}/emis-cloud-credentials`)
    .withCredentials()
    .then(response => {
      dispatch({ type: API_GET_EMIS_CREDENTIALS_SUCCESS, payload: response.body });
      return true;
    })
    .catch(error => {
      if (error.response?.status === 404) {
        dispatch({ type: API_GET_EMIS_CREDENTIALS_SUCCESS, payload: { emisOrganisationID: '', username: '' } });
        return true;
      }
      dispatch({ type: API_GET_EMIS_CREDENTIALS_ERROR, payload: error });
      return false;
    });
};

export const apiPostEmisCredentials = (practiceId, emisOrganisationId, username, password) => dispatch => {
  dispatch({ type: API_POST_EMIS_CREDENTIALS_REQUEST });

  return request.post(`${PRACTICE_URL}/${practiceId}/emis-cloud-credentials`)
    .withCredentials()
    .send({ emisOrganisationId, username, password })
    .then(() => {
      dispatch({ type: API_POST_EMIS_CREDENTIALS_SUCCESS });
      toast(<SuccessToast message="Credentials saved successfully" />);
      return true;
    })
    .catch(error => {
      dispatch({ type: API_POST_EMIS_CREDENTIALS_ERROR, payload: error });
      reportError(new Error('could not save EMIS credentials'), { practiceId, error });
      toast.error(
        <ErrorToast message="There was an error. Please refresh and try again." />,
        { icon: false, autoClose: 12_000 },
      );
      return false;
    });
};

export const apiDeleteEmisCredentials = practiceId => dispatch => {
  dispatch({ type: API_DELETE_EMIS_CREDENTIALS_REQUEST });

  return request.delete(`${PRACTICE_URL}/${practiceId}/emis-cloud-credentials`)
    .withCredentials()
    .then(() => {
      dispatch({ type: API_DELETE_EMIS_CREDENTIALS_SUCCESS });
      toast(<SuccessToast message="Credentials deleted successfully" />);
      return true;
    })
    .catch(error => {
      if (error.response?.status === 404) {
        dispatch({ type: API_DELETE_EMIS_CREDENTIALS_SUCCESS });
        toast(<SuccessToast message="Credentials deleted successfully" />);
        return true;
      }
      dispatch({ type: API_DELETE_EMIS_CREDENTIALS_ERROR, payload: error });
      reportError(new Error('could not delete EMIS credentials'), { practiceId, error });
      toast.error(
        <ErrorToast message="There was an error. Please refresh and try again." />,
        { icon: false, autoClose: 12_000 },
      );
      return false;
    });
};

export const clearEmisCredentials = () => ({ type: CLEAR_EMIS_CREDENTIALS });
