import { STRINGS } from 'config';

const {
  REQUEST_STATUS: {
    IDLE, PENDING, SUCCEEDED, FAILED,
  },
  REDUCERS: {
    FEATURE_FLAGS: {
      GET_FEATURE_FLAGS_SUCCESS,
    },
    APP: {
      SET_PRACTICE,
    },
    EMIS: {
      API_GET_EMIS_CREDENTIALS_REQUEST,
      API_GET_EMIS_CREDENTIALS_SUCCESS,
      API_GET_EMIS_CREDENTIALS_ERROR,
      API_POST_EMIS_CREDENTIALS_REQUEST,
      API_POST_EMIS_CREDENTIALS_SUCCESS,
      API_POST_EMIS_CREDENTIALS_ERROR,
      API_DELETE_EMIS_CREDENTIALS_REQUEST,
      API_DELETE_EMIS_CREDENTIALS_SUCCESS,
      API_DELETE_EMIS_CREDENTIALS_ERROR,
      CLEAR_EMIS_CREDENTIALS,
    },
  },
} = STRINGS;

export const initialEmisState = {
  isEmisEnabledForPractice: false,
  isIntegrationEnabled: false,
  getCredentialsRequestStatus: IDLE,
  postCredentialsRequestStatus: IDLE,
  orgId: '',
  username: '',
};

export const emisReducer = (state, action) => {
  switch (action.type) {
    case SET_PRACTICE: {
      return {
        ...state,
        getCredentialsRequestStatus: IDLE,
      };
    }
    case GET_FEATURE_FLAGS_SUCCESS: {
      return {
        ...state,
        isIntegrationEnabled: action.payload.SETTINGS_FRONTEND_EMIS_CLOUD_ENABLED,
      };
    }
    case API_GET_EMIS_CREDENTIALS_REQUEST: {
      return {
        ...state,
        getCredentialsRequestStatus: PENDING,
        orgId: initialEmisState.orgId,
        username: initialEmisState.username,
      };
    }
    case API_GET_EMIS_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        getCredentialsRequestStatus: SUCCEEDED,
        orgId: action.payload?.emisOrganisationId,
        username: action.payload?.username,
        isEmisEnabledForPractice: !!action.payload,
      };
    }
    case API_GET_EMIS_CREDENTIALS_ERROR: {
      return {
        ...state,
        getCredentialsRequestStatus: FAILED,
        orgId: initialEmisState.orgId,
        username: initialEmisState.username,
        isEmisEnabledForPractice: initialEmisState.isEmisEnabledForPractice,
      };
    }
    case API_POST_EMIS_CREDENTIALS_REQUEST: {
      return {
        ...state,
        postCredentialsRequestStatus: PENDING,
      };
    }
    case API_POST_EMIS_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        postCredentialsRequestStatus: SUCCEEDED,
      };
    }
    case API_POST_EMIS_CREDENTIALS_ERROR: {
      return {
        ...state,
        postCredentialsRequestStatus: FAILED,
        orgId: initialEmisState.orgId,
        username: initialEmisState.username,
      };
    }
    case API_DELETE_EMIS_CREDENTIALS_REQUEST: {
      return { ...state };
    }
    case API_DELETE_EMIS_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        orgId: initialEmisState.orgId,
        username: initialEmisState.username,
      };
    }
    case API_DELETE_EMIS_CREDENTIALS_ERROR: {
      return { ...state };
    }
    case CLEAR_EMIS_CREDENTIALS: {
      return {
        ...state,
        getCredentialsRequestStatus: IDLE,
        orgId: '',
        username: '',
      };
    }
    default: {
      return state;
    }
  }
};
