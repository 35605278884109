import _map from 'lodash/map';
import _join from 'lodash/join';
import { STRINGS } from 'config';

const {
  REQUEST_STATUS: {
    IDLE,
    PENDING,
    SUCCEEDED,
    FAILED,
  },
  REDUCERS: {
    APP: { RESET_ALL_STATE, SET_PRACTICE },
    CUSTOMISATION: {
      GET_CUSTOM_TEAMS_REQUEST,
      GET_CUSTOM_TEAMS_SUCCESS,
      GET_CUSTOM_TEAMS_ERROR,
      UPDATE_CUSTOM_TEAMS_REQUEST,
      UPDATE_CUSTOM_TEAMS_SUCCESS,
      UPDATE_CUSTOM_TEAMS_ERROR,
    },
  },
} = STRINGS;

const formatCustomTeamsToString = teams => _join(_map(teams, 'displayName'), ', ');

export const initialCustomisationState = {
  teams: '',
  getTeamsRequestStatus: IDLE,
  updateTeamsRequestStatus: IDLE,
};

export const customisationReducer = (state, action) => {
  switch (action.type) {
    case SET_PRACTICE:
    case RESET_ALL_STATE: {
      return initialCustomisationState;
    }
    case GET_CUSTOM_TEAMS_REQUEST:
      return { ...state, teams: initialCustomisationState.teams, getTeamsRequestStatus: PENDING };
    case GET_CUSTOM_TEAMS_SUCCESS:
      return { ...state, teams: formatCustomTeamsToString(action.payload), getTeamsRequestStatus: SUCCEEDED };
    case GET_CUSTOM_TEAMS_ERROR:
      return { ...state, teams: initialCustomisationState.teams, getTeamsRequestStatus: FAILED };
    case UPDATE_CUSTOM_TEAMS_REQUEST:
      return { ...state, updateTeamsRequestStatus: PENDING };
    case UPDATE_CUSTOM_TEAMS_SUCCESS:
      return { ...state, teams: formatCustomTeamsToString(action.payload), updateTeamsRequestStatus: SUCCEEDED };
    case UPDATE_CUSTOM_TEAMS_ERROR:
      return { ...state, updateTeamsRequestStatus: FAILED };
    default: {
      return state;
    }
  }
};
