import React, { useEffect, useState, useRef } from 'react';
import _includes from 'lodash/includes';
import _split from 'lodash/split';
import _map from 'lodash/map';
import _trim from 'lodash/trim';
import _compact from 'lodash/compact';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import { toast } from '@econsult/econsult-ui/dist/components/Toast';
import { Button } from '@econsult/econsult-ui/dist/components/Button';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { getCustomTeams, updateCustomTeams } from 'store/action/customisation';
import { STRINGS } from 'config';
import { ParagraphBaseHeavy, TextFlow } from 'components/Typography';
import {
  Form,
  FieldSet,
  Legend,
  Validation,
  FlexInputLabel,
  FlexInput,
  FlexInputSpan,
} from 'components/Form';
import { InputRow } from 'components/ScheduleClosureForm/style';
import { ContactAdminTooltip } from 'components/Tooltip';
import { SuccessToast } from 'components/Toast';
import { useAutoResizeTextarea } from 'utils/customHooks';
import { hasDuplicates } from 'utils/lists';

const { REQUEST_STATUS: { PENDING } } = STRINGS;

const formatTeamsIntoCustomisationValue = teams => (
  _map(teams, team => ({ id: team, displayName: team }))
);

const formatTeamsIntoTrimmedList = teams => _compact(_map(_split(teams, ','), _trim));

export const CustomTeamsForm = () => {
  const dispatch = useAppDispatch();
  const {
    app: {
      selectedPractice,
      selectedPracticeHasAdminRights,
    },
    customisation: {
      teams: currentTeams,
      getTeamsRequestStatus,
      updateTeamsRequestStatus,
    },
  } = useAppState();

  useEffect(() => {
    getCustomTeams(selectedPractice.id)(dispatch);
  }, [dispatch, selectedPractice.id]);

  const [teams, setTeams] = useState(() => currentTeams);
  const [validation, setValidation] = useState('');
  const textareaRef = useRef();
  const { triggerResize } = useAutoResizeTextarea(textareaRef);

  useEffect(() => {
    setTeams(currentTeams);
    triggerResize();
  }, [currentTeams, triggerResize]);

  const onSubmit = event => {
    event.preventDefault();
    const { customTeams: { value: customTeams } } = event.target.elements;
    const customTeamsList = formatTeamsIntoTrimmedList(customTeams);
    if (_isEmpty(customTeamsList)) {
      return setValidation('Teams cannot be empty');
    }
    if (hasDuplicates(customTeamsList)) {
      return setValidation('Please remove duplicate team values');
    }
    setValidation('');
    if (_isEqual(customTeamsList, _split(currentTeams, ', '))) {
      setTeams(currentTeams);
      return toast(<SuccessToast message="Teams already up to date" />);
    }
    return updateCustomTeams(selectedPractice.id, formatTeamsIntoCustomisationValue(customTeamsList))(dispatch);
  };

  const isFormDisabled = !selectedPracticeHasAdminRights
  || _includes([getTeamsRequestStatus, updateTeamsRequestStatus], PENDING);

  return (
    <TextFlow data-automation="CustomiseTeamsContent">
      <ParagraphBaseHeavy as="h2" data-automation="CustomiseTeamsFormHeading">Customise Smart Inbox Teams</ParagraphBaseHeavy>
      <Form action="" onSubmit={onSubmit} autocomplete="off" data-automation="CustomiseTeamsForm">
        {validation && <Validation state="error" textContent={validation} variant="filled" automationName="CustomTeamsValidation" />}
        <FieldSet>
          <Legend data-automation="CustomiseTeamsFormDescription">
            These show in the signposting dropdown. Ensure they are entered in the order you want them to be shown.
          </Legend>
          <InputRow>
            <FlexInputLabel htmlFor="SmartInboxTeamsInput" state={validation ? 'error' : 'default'}>
              <FlexInputSpan state={validation ? 'error' : 'default'} htmlFor="SmartInboxTeamsInput">Smart Inbox Teams</FlexInputSpan>
              <FlexInput
                type="text"
                name="customTeams"
                value={teams}
                onChange={event => setTeams(event.target.value)}
                data-automation="SmartInboxTeamsInput"
                readOnly={isFormDisabled}
                id="SmartInboxTeamsInput"
                state={validation ? 'error' : 'default'}
                ref={textareaRef}
                as="textarea"
              />
            </FlexInputLabel>
            <Button size="small" type="submit" data-automation="SaveCustomTeamsButton" disabled={isFormDisabled}>
              Save custom teams
              <ContactAdminTooltip show={!selectedPracticeHasAdminRights} automationLabel="SaveCustomTeamsDisabledTooltip" />
            </Button>
          </InputRow>
        </FieldSet>
      </Form>
    </TextFlow>
  );
};
