import React from 'react';
import { Feature, RenderOn } from '@econsult/econsult-ui/dist/components/FlagControlledFeature';
import { EmisCredentials } from 'components/EmisCredentials';
import { TableContainer, TableHeaderDivider, TableHeaderSmallText, TableHeaderTitleText } from 'components/Table';
import { HeaderColumn, HeaderRow } from 'components/Table/tableHeader';
import { STRINGS } from 'config';
import { CustomTeamsForm } from './CustomTeamsForm';

export const OtherSettings = () => (
  <TableContainer id="SkipToUserListMainContent" data-automation="OtherSettingsWrapper">
    <HeaderColumn>
      <HeaderRow>
        <HeaderColumn>
          <TableHeaderTitleText as="h1" data-automation="OtherSettingsPageTitleText">
            Other settings
          </TableHeaderTitleText>
          <TableHeaderSmallText data-automation="OtherSettingsDescription">
            Other settings to customise and manage your eConsult experience.
          </TableHeaderSmallText>
        </HeaderColumn>
      </HeaderRow>
      <TableHeaderDivider />
    </HeaderColumn>
    <Feature flags={[STRINGS.FEATURE_FLAGS.SETTINGS_FRONTEND_EMIS_CLOUD_ENABLED]}>
      <RenderOn>
        <EmisCredentials />
      </RenderOn>
    </Feature>
    <CustomTeamsForm />
  </TableContainer>
);
