import React from 'react';
import { STRINGS } from 'config';
import request from 'superagent';
import { toast } from '@econsult/econsult-ui/dist/components/Toast';
import { ErrorToast, SuccessToast } from 'components/Toast';
import { retryCallback } from 'utils/apiRequests';

const {
  API: { CUSTOMISATION_URL },
  FEATURES: { SMART_INBOX_PRIMARY_CARE_TEAM },
  REDUCERS: {
    CUSTOMISATION: {
      GET_CUSTOM_TEAMS_REQUEST,
      GET_CUSTOM_TEAMS_SUCCESS,
      GET_CUSTOM_TEAMS_ERROR,
      UPDATE_CUSTOM_TEAMS_REQUEST,
      UPDATE_CUSTOM_TEAMS_SUCCESS,
      UPDATE_CUSTOM_TEAMS_ERROR,
    },
  },
} = STRINGS;

export const getCustomTeams = practiceId => dispatch => {
  dispatch({ type: GET_CUSTOM_TEAMS_REQUEST });
  return request
    .get(`${CUSTOMISATION_URL}/feature/${SMART_INBOX_PRIMARY_CARE_TEAM}/${practiceId}`)
    .set({ 'X-OWNER-ID': practiceId })
    .retry(3, retryCallback(dispatch, GET_CUSTOM_TEAMS_REQUEST))
    .then(response => dispatch({ type: GET_CUSTOM_TEAMS_SUCCESS, payload: response.body.value }))
    .catch(error => {
      dispatch({ type: GET_CUSTOM_TEAMS_ERROR, payload: error });
      toast.error(
        <ErrorToast message="Unable to get teams." />,
        { icon: false },
      );
    });
};

export const updateCustomTeams = (practiceId, value) => dispatch => {
  dispatch({ type: UPDATE_CUSTOM_TEAMS_REQUEST });
  return request
    .post(`${CUSTOMISATION_URL}/public/feature/value`)
    .withCredentials()
    .set({ 'X-OWNER-ID': practiceId })
    .send({
      featureId: SMART_INBOX_PRIMARY_CARE_TEAM,
      value,
    })
    .retry(3, retryCallback(dispatch, UPDATE_CUSTOM_TEAMS_REQUEST))
    .then(response => {
      dispatch({ type: UPDATE_CUSTOM_TEAMS_SUCCESS, payload: response.body.value });
      toast(<SuccessToast message="Successfully updated custom teams" />);
    })
    .catch(error => {
      dispatch({ type: UPDATE_CUSTOM_TEAMS_ERROR, payload: error });
      toast.error(
        <ErrorToast message="Unable to update custom teams. Please try again." />,
        { icon: false },
      );
    });
};
