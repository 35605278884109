import React, { useEffect, useState } from 'react';
import { ParagraphBaseHeavy, ParagraphXSmallHeavy, TextFlow } from 'components/Typography';
import { FieldSet, Legend } from 'components/Form';
import { ExternalLink } from 'components/ExternalLink';
import { InputRow } from 'components/ScheduleClosureForm/style';
import { Button } from '@econsult/econsult-ui/dist/components/Button';
import { StyledInput } from 'components/FormInput/style';
import { DeleteButton, EmisCredentialsForm } from 'components/EmisCredentials/style';
import { useAppDispatch, useAppState } from 'store/configureStore';
import { apiDeleteEmisCredentials, apiGetEmisCredentials, apiPostEmisCredentials, clearEmisCredentials } from 'store/action/emis';
import { STRINGS } from 'config';
import { EConsultDeleteIcon2 } from '@econsult/econsult-ui/dist/assets';
import { ConfirmActionModal } from 'components/Modal/ConfirmActionModal';
import { TableHeaderSmallText } from 'components/Table';
import { removeWhitespace } from 'utils/string';
import { useLocation } from 'react-router-dom';

const { IDLE, SUCCEEDED } = STRINGS.REQUEST_STATUS;

export const EmisCredentials = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    app: {
      selectedPractice,
      selectedPracticeHasAdminRights,
    },
    emis,
  } = useAppState();

  const [orgId, setOrgId] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [orgIdError, setOrgIdError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

  const isDeleteButtonDisabled = !selectedPracticeHasAdminRights || (emis.getCredentialsRequestStatus === SUCCEEDED && (!emis.orgId && !emis.username));
  const isSaveButtonDisabled = !selectedPracticeHasAdminRights;

  const resetForm = () => {
    setOrgId(emis.orgId ?? '');
    setUsername(emis.username ?? '');
    setPassword('');
  };

  const validateCredentials = () => {
    const trimmedOrgId = removeWhitespace(orgId);
    const isOrgIdNumeric = /^[0-9]+$/.test(trimmedOrgId);
    const isOrgId6Chars = trimmedOrgId.length === 6;
    const isOrgIdValid = trimmedOrgId && isOrgIdNumeric && isOrgId6Chars;

    if (!isOrgId6Chars) {
      setOrgIdError('ID must be 6 digits.');
    }
    if (!isOrgIdNumeric) {
      setOrgIdError('ID must be numbers only.');
    }
    if (!trimmedOrgId) {
      setOrgIdError('ID is required.');
    }

    setUsernameError(!username ? 'Username is required.' : '');
    setPasswordError(!password ? 'Password is required.' : '');

    if (!isOrgIdValid || !username || !password) {
      return false;
    }

    setOrgIdError('');
    setUsernameError('');
    setPasswordError('');

    return true;
  };

  const saveCredentials = event => {
    event.preventDefault();

    if (!validateCredentials()) {
      return;
    }

    apiPostEmisCredentials(selectedPractice.id, removeWhitespace(orgId), username, password)(dispatch)
      .then(isSuccess => {
        if (isSuccess) {
          resetForm(event.target);
          apiGetEmisCredentials(selectedPractice.id)(dispatch)
            .then(() => {
              setOrgId(emis.orgId);
              setUsername(emis.username);
            });
        }
      });
  };

  const deleteCredentials = () => {
    setIsConfirmDeleteModalOpen(false);
    apiDeleteEmisCredentials(selectedPractice.id)(dispatch);
  };

  useEffect(() => {
    setOrgId(emis.orgId);
    setUsername(emis.username);
  }, [emis.orgId, emis.username]);

  useEffect(() => {
    if (emis.getCredentialsRequestStatus === IDLE) {
      apiGetEmisCredentials(selectedPractice.id)(dispatch);
    }
  }, [location, dispatch, emis.getCredentialsRequestStatus, selectedPractice.id]);

  useEffect(() => dispatch(clearEmisCredentials()), [dispatch]);

  return (
    emis.isEmisEnabledForPractice && (
      <>
        <TextFlow data-automation="EmisCredentialsContent">
          <ParagraphBaseHeavy as="h2" data-automation="EmisIntegrationSectionHeading">EMIS browser integration</ParagraphBaseHeavy>

          <TableHeaderSmallText data-automation="EmisIntegrationDescription">
            Configuration for your practice to use the integrated Smart Inbox accessed from the browser.
          </TableHeaderSmallText>

          <ParagraphXSmallHeavy as="h3" data-automation="EmisApiCredentialsFormTitle">
            EMIS API credentials
          </ParagraphXSmallHeavy>

          <EmisCredentialsForm action="" onSubmit={saveCredentials} autocomplete="off" data-automation="EmisCredentialsForm">
            <FieldSet>
              <Legend data-automation="EmisApiCredentialsFormDescription">
                Add the credentials for your EMIS API user to connect the Smart Inbox
                to your EMIS instance. You can find out how to do this in our <ExternalLink
                  label="help centre."
                  automationName="link-to-emis-info"
                  url="https://help.econsult.health/en/"
                />
              </Legend>

              <InputRow>
                <StyledInput
                  type="text"
                  value={orgId}
                  inputMode="numeric"
                  label="EMIS Organisation ID (CRD)"
                  onChange={event => setOrgId(event.target.value)}
                  automationName="EmisOrgIdInput"
                  autoComplete="off"
                  state={!orgIdError ? 'default' : 'error'}
                  message={orgIdError}
                />

                <StyledInput
                  type="text"
                  label="Username"
                  value={username}
                  onChange={event => setUsername(event.target.value)}
                  automationName="UsernameInput"
                  autoComplete="off"
                  state={!usernameError ? 'default' : 'error'}
                  message={usernameError}
                />

                <StyledInput
                  type="password"
                  label="Password"
                  autoComplete="off"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  automationName="PasswordInput"
                  state={!passwordError ? 'default' : 'error'}
                  message={passwordError}
                />

                <Button
                  size="small"
                  type="submit"
                  data-automation="SaveEmisCredentialsButton"
                  disabled={isSaveButtonDisabled}
                >
                  Save credentials
                </Button>

                <DeleteButton
                  type="button"
                  icon={<EConsultDeleteIcon2 />}
                  data-automation="DeleteEmisCredentialsButton"
                  onClick={() => setIsConfirmDeleteModalOpen(true)}
                  disabled={isDeleteButtonDisabled}
                >
                  <span>
                    Delete credentials
                  </span>
                </DeleteButton>
              </InputRow>

            </FieldSet>
          </EmisCredentialsForm>
        </TextFlow>
        <ConfirmActionModal
          isOpen={isConfirmDeleteModalOpen}
          onCancel={() => setIsConfirmDeleteModalOpen(false)}
          onConfirm={deleteCredentials}
          actionName="DeleteEmisCredentials"
          // eslint-disable-next-line max-len
          confirmActionModalCopy={'Are you sure you want to delete these credentials?\n\nThis could lead to the Smart Inbox no longer integrating\nwith EMIS for all practice staff.'}
          confirmActionButtonText="Delete"
        />
      </>
    )
  );
};
